import Vue from 'vue';

import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import i18n from "@/plugins/i18n";
import App from '@/App.vue';
import AuthService from '@/services/auth.service';

import "@/assets/css/master.scss"

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, {
    siteKey: '6LcVdMobAAAAAP1ROPwRKvJ3UAEg2JFRzcHbyFOe',
    loaderOptions: {
        autoHideBadge: true
    }
});

Vue.prototype.$auth = AuthService;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
