const routes =
    [
        {
            path: "/",
            name: "Application",
            components: {
                content: () => import("@/views/Application"),
            },
            meta: {
                title: "pages.application.title",
                public: true,
            }
        },
        {
            path: "/auth",
            name: "Auth",
            components: {
                content: () => import("@/views/Auth"),
            },
            meta: {
                title: "pages.auth.title",
                public: true,
            }
        },
        {
            path: "/admin",
            name: "Admin",
            components: {
                content: () => import("@/views/Admin"),
            },
            meta: {
                title: "pages.admin.title",
            }
        },
        {
            path: "/admin/auth",
            name: "Admin Auth",
            components: {
                content: () => import("@/views/AdminAuth"),
            },
            meta: {
                title: "pages.auth.title",
                public: true,
            }
        },
        {
            path: "/404",
            name: "NotFound",
            components: {
                content: () => import("@/views/404"),
            },
            meta: {
                public: true,
                title: "pages.404.title",
            }
        },
        {
            path: "*",
            redirect: "/404"
        }
    ];

export default routes