import ApiSecure from "@/services/api/api-secure";

const initialState = () => ({
    current: null,
    totalLength: 3,
    firstItemNum: null,
    lastItemNum: null,
    totalPageCount: null,
    applications: []
});

function _formatApplication (a) {
    return {
        ...a,
        createdDate: (new Date(a.createdDate)).toLocaleDateString(undefined, {
            minute: 'numeric',
            hour: 'numeric',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }) || "-",
        //author: `${a.author.surname} ${a.author.name}`,
        name: a.author.name || "-",
        email: a.author.email || "-",
        finishStatus: a.finishStatus === "finished",
    }
}

/* Module .store.js */


// VUEX STATE
const state = initialState();


// VUEX GETTERS
const getters = {
    getApplications: (state) => state.applications.map(_formatApplication),
};


// VUEX ACTIONS
const actions = {
    reset({commit}) {
        commit('RESET');
    },
    async fetchApplications({commit, rootGetters}) {
        const res = await ApiSecure.post(
            "application/list",
            rootGetters["Filters/getFilters"]
        );
        if (res) {
            commit("APPLICATIONS", res);
            return true;
        } else return false;
    },
    formatApplication(_, application) {
        return _formatApplication(application)
    }
};


// VUEX MUTATIONS
const mutations = {
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    APPLICATIONS(state, payload) {
        state.applications = payload.applications;
        state.totalLength = payload.totalLength;
        state.firstItemNum = payload.firstItemNum;
        state.lastItemNum = payload.lastItemNum;
        state.totalPageCount = payload.totalPageCount;
    }
};


export default {
    state,
    getters,
    actions,
    mutations
};
