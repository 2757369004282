import ApiSecure from "@/services/api/api-secure";

const initialState = () => ({
    dateBegin: null,
    dateEnd: null,
    finishStatuses: [],
    foreignLanguages: [],
    itemsPerPage: 0,
    lastWork: [],
    locations: [],
    orderBy: null,
    orderType: "desc",
    pageNum: 0,
    profileTypes: [],
    statuses: [],
    testResultMax: 0,
    testResultMin: 0,
    userIds: [],
    education: null,

    minDate: new Date(),
    maxDate: new Date(),

    userItems: [],
});


/* Module .store.js */


// VUEX STATE
const state = initialState();


// VUEX GETTERS
const getters = {
    isFiltered(state) {
        let count = 0;
        count+= state.finishStatuses.length > 0 ? 1 : 0;
        count+= state.foreignLanguages.length > 0 ? 1 : 0;
        count+= state.profileTypes.length > 0 ? 1 : 0;
        count+= state.statuses.length > 0 ? 1 : 0;
        count+= state.userIds.length > 0 ? 1 : 0;
        count+= state.lastWork.length > 0 ? 1 : 0;
        count+= state.locations.length > 0 ? 1 : 0;
        count+= state.dateBegin ? 1 : 0;
        count+= state.dateEnd ? 1 : 0;
        count+= state.testResultMax ? 1 : 0;
        count+= state.testResultMin ? 1 : 0;
        count+= state.education ? 1 : 0;
        return count;
    },
    getFilters: (state) => ({
        finishStatuses: state.finishStatuses.length ? state.finishStatuses : undefined,
        foreignLanguages: state.foreignLanguages.length ? state.foreignLanguages : undefined,
        profileTypes: state.profileTypes.length ? state.profileTypes : undefined,
        statuses: state.statuses.length ? state.statuses : undefined,
        userIds: state.userIds.length ? state.userIds : undefined,
        lastWork: state.lastWork.length ? state.lastWork : undefined,
        locations: state.locations.length ? state.locations : undefined,
        dateBegin: state.dateBegin || undefined,
        dateEnd: state.dateEnd || undefined,
        testResultMax: state.testResultMax || undefined,
        testResultMin: state.testResultMin || undefined,
        education: state.education || undefined,

        itemsPerPage: state.itemsPerPage || undefined,
        orderBy: state.orderBy || undefined,
        orderType: state.orderType || undefined,
        pageNum: state.pageNum || undefined,
    })
};


// VUEX ACTIONS
const actions = {
    reset({commit}) {
        commit('RESET');
    },
    clearFilter({commit}) {
        commit("CLEAR")
    },
    async fetchUsers({commit}, str) {
        const params = new URLSearchParams();
        params.append("query", str);
        const config = { params };
        let res = [];
        try {
            res = await ApiSecure.get("user/search", config);
            if (!res) res = [];
            commit("USER_ITEMS", res);
            return true;
        } catch {
            commit("USER_ITEMS", []);
            return false;
        }
    }
};


// VUEX MUTATIONS
const mutations = {
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    CLEAR(state) {
        state.dateBegin = null;
        state.dateEnd = null;
        state.finishStatuses = [];
        state.foreignLanguages = [];
        state.itemsPerPage = 0;
        state.lastWork = [];
        state.locations = [];
        state.orderBy = null;
        state.orderType = "desc";
        state.pageNum = 0;
        state.profileTypes = [];
        state.statuses = [];
        state.testResultMax = 0;
        state.testResultMin = 0;
        state.userIds = [];
        state.education = null;
    },
    BEGIN(state, payload) {
        state.dateBegin = payload
    },
    END(state, payload) {
        state.dateEnd = payload
    },
    FINISH_STATUS(state, payload) {
        state.finishStatuses = payload
    },
    STATUS(state, payload) {
        state.statuses = payload
    },
    LANGUAGES(state, payload) {
        state.foreignLanguages = payload
    },
    PROFILE(state, payload) {
        state.profileTypes = payload
    },
    LOCATION(state, payload) {
        // Goshan change to array
        state.locations = [payload]
    },
    WORK(state, payload) {
        state.lastWork = payload
    },
    TEST_MAX(state, payload) {
        state.testResultMax = payload
    },
    TEST_MIN(state, payload) {
        state.testResultMin = payload
    },
    USER(state, payload) {
        state.userIds = payload
    },
    USER_ITEMS(state, payload) {
        state.userItems = payload
    },
    ITEMS_PER_PAGE(state, payload) {
        state.itemsPerPage = payload
    },
    ORDER_BY(state, payload) {
        state.orderBy = payload
    },
    ORDER_TYPE(state, payload) {
        state.orderType = payload
    },
};


export default {
    state,
    getters,
    actions,
    mutations
};
