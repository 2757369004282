// State initial object
import AuthService from '../../services/auth.service';
import ApiSecure from "@/services/api/api-secure";

const initialState = () => ({
	email: null,
	role: null,
	viewPermissions: [],
	credentials: {
		email: "",
		captcha: "",
		code: "",
	}
});


/* Module .store.js */


// VUEX STATE
const state = initialState();


// VUEX GETTERS
const getters = {
	getRole: (state) => state.role,
	getPermissions: (state) => state.viewPermissions,
	getEmail: (state) => state.email,
	getCredentialsEmail: (state) => state.credentials.email,
	getCredentialsCaptcha: (state) => state.credentials.captcha,
	getCredentialsCode: (state) => state.credentials.code,
	isLoggedIn: (state) => !!state.email
};


// VUEX ACTIONS
const actions = {
	reset({commit}) {
		commit('RESET');
	},
	async init({ commit }) {
		commit('SET', await ApiSecure.get('user/info'));
	},
	async code({ state }) {
		return await AuthService.login_code(state.credentials.email,state.credentials.captcha);
	},
	async login({ state }) {
		return await AuthService.login_email(state.credentials.email, state.credentials.code)
	}
};


// VUEX MUTATIONS
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		});
	},
	SET(state, {email, role, viewPermissions}) {
		state.email = email;
		state.role = role;
		state.viewPermissions = viewPermissions;
	},
	EMAIL(state, payload) {
		state.credentials.email = payload;
	},
	CAPTCHA(state, payload) {
		state.credentials.captcha = payload;
	},
	CODE(state, payload) {
		state.credentials.code = payload;
	},
};


export default {
	state,
	getters,
	actions,
	mutations
};